import { Amplify } from 'aws-amplify';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'application/store/store';
import amplify from 'infra/config/amplify';
import App from 'ui/App';
import 'application/i18n';
import 'ui/assets/styles/main.css';
import Initializer from 'ui/Initializer';
import LayoutMain from 'ui/components/layouts/LayoutMain';
import { SliderProvider } from 'ui/components/wrappers/Slider';

Amplify.configure({ ...amplify });

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <SliderProvider>
      <LayoutMain>
        <Initializer>
          <App />
        </Initializer>
      </LayoutMain>
    </SliderProvider>
  </Provider>
);
