import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { patchAppState } from 'application/store/app/app.slice';
import ReportThunks from 'application/store/report/report.thunks';
import { AppDispatch } from 'application/store/store';
import { CheckUtil } from 'application/utils/check.util';
import ScreenError from 'ui/components/commons/ScreenError';
import ScreenLoader from 'ui/components/commons/ScreenLoader';
import useQuery from 'ui/hooks/use-query';

type Props = {
  children: React.ReactNode;
};

const Initializer: React.FC<Props> = ({ children }): JSX.Element | null => {
  const [error, setError] = useState<string>();
  const [ready, setReady] = useState<boolean>(false);

  const token = useQuery('token');
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (ready) return;

    if (!token) {
      setError('JWT Token is missing');
      return;
    }

    const onInit = async () => {
      try {
        await dispatch(patchAppState({ token }));
        await dispatch(ReportThunks.getReport());
      } catch (e: unknown) {
        const err = CheckUtil.hasErrorMessage(e) ? e.message : 'Unknown error';
        setError(err);
      }
    };
    onInit().then(() => setReady(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <ScreenError message={error} />;

  if (!ready) return <ScreenLoader />;

  return <>{children}</>;
};

export default Initializer;
