const amplifyConfigByEnv = {
  apiGateway: {
    NAME: process.env.REACT_APP_API_NAME,
    REGION: process.env.REACT_APP_REGION,
    ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  },
};

const amplify = {
  API: {
    endpoints: [
      {
        name: amplifyConfigByEnv.apiGateway.NAME,
        region: amplifyConfigByEnv.apiGateway.REGION,
        endpoint: amplifyConfigByEnv.apiGateway.ENDPOINT,
      },
    ],
  },
};

export default amplify;
