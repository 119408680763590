import React from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import styles from './Badge.module.css';

type BadgeColor = 'green' | 'yellow' | 'orange' | 'gray';

export type BadgeVariant = 'filled' | 'outlined';

type Props = {
  color: BadgeColor;
  variant?: BadgeVariant;
};

export type BadgeProps = HTMLDivAttributesWithRef<Props>;

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ color, variant, className, children, ...props }, ref): JSX.Element | null => (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      data-color={color}
      data-variant={variant || 'filled'}
      className={`${styles.root} ${className}`}
    >
      {children}
    </div>
  )
);

export default Badge;
