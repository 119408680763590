import React from 'react';
import styles from './Loader.module.css';

type Size = 'xs' | 'sm' | 'md';

type Props = {
  readonly size?: Size;
  readonly className?: string;
};

const Loader: React.FC<Props> = ({ className, size = 'md' }): JSX.Element | null => (
  <div data-size={size} className={`${styles.root} ${className}`}>
    <svg className={styles.svg} viewBox="25 25 50 50">
      <circle className={styles.circle} cx="50" cy="50" r="20" />
    </svg>
  </div>
);

export default Loader;
