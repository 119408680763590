import React, { HTMLProps, useEffect, useState } from 'react';
import { CheckUtil } from 'application/utils/check.util';
import ButtonIcon from 'ui/components/commons/ButtonIcon';
import { LeftArrow } from 'ui/components/commons/Icons';
import { PostMessageHelper } from 'ui/helpers/post-message.helper';
import styles from './Header.module.css';

type Props = HTMLProps<HTMLDivElement> & {
  className?: string;
};

const Header = React.forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref): JSX.Element | null => {
  const [showShadow, setShowShadow] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      setShowShadow(document.documentElement.scrollTop > 0);
    };
    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // Hide Header if it's not an iframe
  if (!CheckUtil.isIframe()) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`} data-shadow={showShadow}>
      <ButtonIcon className={styles.back} onClick={PostMessageHelper.closeIframe}>
        <LeftArrow />
      </ButtonIcon>
    </div>
  );
});

export default Header;
