/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReportSelectors from 'application/store/report/report.selectors';
import ResultsBadges from 'ui/components/commons/ResultsBadges';
import ResultsWeights from 'ui/components/commons/ResultsWeights';
import ScreenError from 'ui/components/commons/ScreenError';
import ScreenLoader from 'ui/components/commons/ScreenLoader';
import Section from 'ui/components/commons/Section';
import styles from './Home.module.css';

const Home: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation();

  const { data, pending, error } = useSelector(ReportSelectors.state);

  if (error) return <ScreenError message={error} />;

  if (pending) return <ScreenLoader />;

  if (!data) return <ScreenError message="Unknown error" />;

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('commons.my-results')}</div>

      <div className={styles.sections}>
        <Section data-col-span={4}>
          <Section.Title>
            <Trans t={t} i18nKey="titles.traits" />
          </Section.Title>
          <Section.Body>
            <ResultsWeights sessions={data.traits.sessions} />
          </Section.Body>
        </Section>

        <Section data-col-span={4}>
          <Section.Title>
            <Trans t={t} i18nKey="titles.motivations" />
          </Section.Title>
          <Section.Body>
            <ResultsWeights sessions={data.motivations.sessions} />
          </Section.Body>
        </Section>

        <Section data-col-span={4}>
          <Section.Title>
            <Trans t={t} i18nKey="titles.coping-strategy" />
          </Section.Title>
          <Section.Body>
            <ResultsWeights sessions={data.copingStrategy.sessions} />
          </Section.Body>
        </Section>

        <Section data-col-span={4}>
          <Section.Title>
            <Trans t={t} i18nKey="titles.ability-to-act" />
          </Section.Title>
          <Section.Body className="flex flex-col gap-8">
            {data.abilityToAct.items.map(({ cursor, sessions, title }, i) => (
              <ResultsBadges key={i}>
                <ResultsBadges.Cursor cursor={cursor} />
                <ResultsBadges.Sessions sessions={sessions} />
                <ResultsBadges.Title>{title}</ResultsBadges.Title>
              </ResultsBadges>
            ))}
          </Section.Body>
        </Section>

        <Section data-col-span={4}>
          <Section.Title>
            <Trans t={t} i18nKey="titles.mediators" />
          </Section.Title>
          <Section.Body className="flex flex-col gap-8">
            {data.mediators.items.map(({ cursor, sessions, title }, i) => (
              <ResultsBadges key={i}>
                <ResultsBadges.Cursor cursor={cursor} />
                <ResultsBadges.Sessions sessions={sessions} />
                <ResultsBadges.Title>{title}</ResultsBadges.Title>
              </ResultsBadges>
            ))}
          </Section.Body>
        </Section>

        <Section data-col-span={4}>
          <Section.Title>
            <Trans t={t} i18nKey="titles.learning" />
          </Section.Title>
          <Section.Body className="flex flex-col gap-8">
            {data.learning.items.map(({ cursor, sessions, title }, i) => (
              <ResultsBadges key={i}>
                <ResultsBadges.Cursor cursor={cursor} />
                <ResultsBadges.Sessions sessions={sessions} />
                <ResultsBadges.Title>{title}</ResultsBadges.Title>
              </ResultsBadges>
            ))}
          </Section.Body>
        </Section>

        <Section data-col-span={4}>
          <Section.Title>
            <Trans t={t} i18nKey="titles.employability" />
          </Section.Title>
          <Section.Body>
            <ResultsWeights sessions={data.employability.sessions} />
          </Section.Body>
        </Section>
      </div>
    </div>
  );
};

export default Home;
