/* eslint-disable no-nested-ternary */
import { cloneDeep, isArray, isObject } from 'lodash';
import { Report, SessionWithWeights } from 'domain/models/report.model';
import { APIQuestionnaireWithWeight, APIResults, APISession } from 'infra/models/results.model';
import { CheckUtil } from '../utils/check.util';

class ReportMapper {
  private static extractDates = (results: APIResults): (Date | null)[] => {
    const allSessions: APISession[][] = [
      // Learning
      results.affective['876'].sessions,
      results.cognitive['876'].sessions,
      results.conative['876'].sessions,
      // Ability to act
      results.agency['821'].sessions,
      results.selfEfficacy['820'].sessions,
      // Adjust Strategy
      results.avoiding['819'].sessions,
      results.socialSupport['819'].sessions,
      results.problemFocused['819'].sessions,
      // Mediators
      results.internalControl['822'].sessions,
      results.externalControl['822'].sessions,
      // Traits
      results.openness['874'].sessions,
      results.awareness['874'].sessions,
      results.comfort['874'].sessions,
      results.extraversion['874'].sessions,
      results.nevrosism['874'].sessions,
      // Motivations
      results.amotivation['875'].sessions,
      results.extrinsicMotivationExternalRegulation['875'].sessions,
      results.extrinsicMotivationIdentified['875'].sessions,
      results.extrinsicMotivationIntrojected['875'].sessions,
      results.intrinsicAchievementMotivation['875'].sessions,
      results.intrinsicMotivationForKnowledge['875'].sessions,
      results.intrinsicMotivationToStimulate['875'].sessions,
    ];
    const orderedDates = allSessions.reduce((acc: (Date | null)[][], curr) => {
      curr.forEach((obj, i) => {
        acc[i] = acc[i] || [];
        acc[i].push(obj.date ? new Date(obj.date) : null);
      });
      return acc;
    }, []);
    return orderedDates.map((dates) =>
      dates.some(Boolean) ? new Date(Math.max(...(dates.filter(Boolean) as Date[]).map((d) => d.getTime()))) : null
    );
  };

  private static updateSessionsDates = (data: unknown, dates: (Date | null)[]): unknown => {
    const clone = cloneDeep(data);

    const updateDates = (item: unknown): unknown =>
      CheckUtil.hasSessions(item)
        ? {
            ...item,
            sessions: item.sessions.map((session, i: number) => ({ ...session, date: dates[i] })),
          }
        : item;

    if (isArray(clone)) {
      return clone.map((item) => ReportMapper.updateSessionsDates(item, dates));
    }

    if (isObject(clone)) {
      const updatedClone = updateDates(clone) as { [key: string]: unknown };
      for (const key in updatedClone) {
        if (updatedClone[key]) updatedClone[key] = ReportMapper.updateSessionsDates(updatedClone[key], dates);
      }

      return updatedClone;
    }

    return clone;
  };

  private static symptomsToWeightsSessions = (symptoms: APIQuestionnaireWithWeight[]): SessionWithWeights[] =>
    symptoms[0].sessions
      .map(({ date }) => (date ? new Date(date) : null))
      .map((date, i) => ({
        date,
        symptoms: symptoms.map((symptom) => ({
          title: symptom.title,
          color: symptom.sessions[i]?.color,
          score: symptom.sessions[i]?.score,
          weight: symptom.sessions[i]?.weight,
        })),
      }));

  static resultsToReport = (results: APIResults): Report =>
    ReportMapper.updateSessionsDates(
      {
        copingStrategy: {
          sessions: ReportMapper.symptomsToWeightsSessions([
            results.avoiding['819'],
            results.socialSupport['819'],
            results.problemFocused['819'],
          ]),
        },
        learning: {
          items: [results.affective['876'], results.cognitive['876'], results.conative['876']],
        },
        abilityToAct: {
          items: [results.agency['821'], results.selfEfficacy['820']],
        },
        mediators: {
          items: [results.internalControl['822'], results.externalControl['822']],
        },
        traits: {
          sessions: ReportMapper.symptomsToWeightsSessions([
            results.openness['874'],
            results.awareness['874'],
            results.comfort['874'],
            results.extraversion['874'],
            results.nevrosism['874'],
          ]),
        },
        motivations: {
          sessions: ReportMapper.symptomsToWeightsSessions([
            results.intrinsicMotivationForKnowledge['875'],
            results.intrinsicAchievementMotivation['875'],
            results.intrinsicMotivationToStimulate['875'],
            results.extrinsicMotivationIdentified['875'],
            results.extrinsicMotivationIntrojected['875'],
            results.extrinsicMotivationExternalRegulation['875'],
            results.amotivation['875'],
          ]),
        },
        employability: {
          sessions: ReportMapper.symptomsToWeightsSessions([
            results.humanCapital['977'],
            results.teamWork['977'],
            results.criticMind['977'],
            results.academic['977'],
          ]),
        },
      },
      ReportMapper.extractDates(results)
    ) as Report;
}

export default ReportMapper;
