import React, { SVGProps } from 'react';

const LeftArrow = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      d="M24.0212 10.59C24.022 10.9873 23.8649 11.3687 23.5845 11.6502C23.3041 11.9318 22.9234 12.0904 22.526 12.0912L5.16773 12.1263L11.614 18.5436C11.7536 18.6826 11.8645 18.8478 11.9402 19.0296C12.016 19.2115 12.0552 19.4064 12.0556 19.6034C12.056 19.8004 12.0176 19.9956 11.9425 20.1777C11.8675 20.3599 11.7573 20.5255 11.6183 20.6651C11.4793 20.8046 11.3142 20.9155 11.1323 20.9912C10.9505 21.067 10.7555 21.1062 10.5585 21.1066C10.3615 21.107 10.1663 21.0686 9.98419 20.9936C9.80204 20.9185 9.63644 20.8084 9.49687 20.6694L0.489435 11.6982C0.34963 11.5594 0.238601 11.3943 0.162704 11.2124C0.0868074 11.0305 0.0475338 10.8355 0.0471356 10.6384C0.0467374 10.4413 0.0852224 10.2461 0.160384 10.064C0.235545 9.88179 0.345905 9.71623 0.485148 9.57678L9.45625 0.569345C9.59527 0.429766 9.76042 0.318935 9.94226 0.24318C10.1241 0.167425 10.3191 0.128229 10.5161 0.127831C10.7131 0.127433 10.9082 0.165841 11.0904 0.24086C11.2725 0.31588 11.4381 0.426043 11.5777 0.565059C11.8596 0.845814 12.0184 1.22705 12.0192 1.62491C12.0196 1.8219 11.9812 2.01705 11.9062 2.1992C11.8312 2.38135 11.721 2.54695 11.582 2.68653L5.16168 9.12985L22.52 9.09478C22.9173 9.09397 23.2987 9.25105 23.5802 9.53145C23.8618 9.81185 24.0204 10.1926 24.0212 10.59Z"
    />
  </svg>
);

export default LeftArrow;
