import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState from './app.default';
import { AppState } from './app.model';

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    patchAppState: (state, { payload }: PayloadAction<Partial<AppState>>) => ({ ...state, ...payload }),
  },
});

export const { patchAppState } = appSlice.actions;

export default appSlice.reducer;
