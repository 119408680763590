import React from 'react';
import { HTMLButtonAttributesWithRef } from 'domain/models/shared.model';
import styles from './ButtonOption.module.css';

type Props = {
  isSelected?: boolean;
  isDisabled?: boolean;
};

export type ButtonOptionProps = HTMLButtonAttributesWithRef<Props>;

const ButtonOption = React.forwardRef<HTMLButtonElement, ButtonOptionProps>(
  ({ isDisabled, isSelected, className, children, ...props }, ref): JSX.Element | null => (
    <button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      type="button"
      data-disabled={isDisabled}
      data-selected={isSelected}
      className={`${styles.root} ${className}`}
    >
      {children}
    </button>
  )
);

export default ButtonOption;
