import React from 'react';
import Loader from '../Loader';
import styles from './ScreenLoader.module.css';

const ScreenLoader: React.FC = (): JSX.Element | null => (
  <div className={styles.root}>
    <Loader size="md" />
  </div>
);

export default ScreenLoader;
