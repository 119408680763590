import React from 'react';
import { Cursor as CursorType } from 'domain/models/report.model';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import { Play } from 'ui/components/commons/Icons';
import styles from './Cursor.module.css';

type Props = {
  cursor: CursorType;
};

export type CursorProps = HTMLDivAttributesWithRef<Props>;

const Cursor = React.forwardRef<HTMLDivElement, CursorProps>(
  ({ cursor, className, ...props }, ref): JSX.Element | null => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`}>
      {cursor.colors.map((color, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className={styles.cell} data-color={color}>
          {cursor.currentIndex === i && <Play className={styles.arrow} />}
        </div>
      ))}
    </div>
  )
);

export default Cursor;
