import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import appReducer from './app/app.slice';
import reportReducer from './report/report.slice';
import { Store } from './store.model';

export type AppDispatch = typeof store.dispatch;

const reducer = combineReducers<Store>({
  app: appReducer,
  report: reportReducer,
});

const middlewares: Middleware[] = [];

if (process.env.NODE_ENV !== 'production') {
  import('redux-logger').then(({ createLogger }) => {
    const logger = createLogger({ collapsed: true, duration: true });
    middlewares.push(logger);
  });
}

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});
