import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Report } from 'domain/models/report.model';
import initialState from './report.default';
import { ReportState } from './report.model';
import ReportThunks from './report.thunks';

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ReportState>) => {
    builder.addCase(ReportThunks.getReport.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(ReportThunks.getReport.rejected, (state, action) => {
      state.error = (action.payload as Error).message || 'Unknown error';
      state.pending = false;
    });
    builder.addCase(ReportThunks.getReport.fulfilled, (state, action: PayloadAction<Report>) => {
      state.data = action.payload;
      state.pending = false;
    });
  },
});

export default reportSlice.reducer;
