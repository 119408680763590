import { createAsyncThunk } from '@reduxjs/toolkit';
import ReportMapper from 'application/mappers/report.mapper';
import { Store } from 'application/store/store.model';
import { CheckUtil } from 'application/utils/check.util';
import { Report } from 'domain/models/report.model';
import UsersApi from 'infra/api/users.api';
import i18n from '../../i18n';

class ReportThunks {
  static getReport = createAsyncThunk<Report>('getReport', async (_, { getState, rejectWithValue }) => {
    try {
      const { app } = getState() as Store;
      if (!app.token) return rejectWithValue(new Error('JWT token is missing'));
      const results = await UsersApi.getMeResults(app.token);
      return ReportMapper.resultsToReport(results);
    } catch (e: unknown) {
      const error = CheckUtil.hasErrorResponse(e) ? e.response : e;

      const hasStatus = CheckUtil.hasErrorStatus(error);
      const hasMessage = CheckUtil.hasErrorMessage(error);
      const hasDataMessage = CheckUtil.hasErrorDataMessage(error);

      let message = '';
      if (hasMessage) message = error.message;
      if (hasDataMessage) message = error.data.message;
      if (hasStatus && error.status === 401) message = i18n.t('errors.unauthorized');
      if (hasStatus && error.status === 412) message = i18n.t('errors.uncompleted');

      return rejectWithValue(new Error(message || 'Unknown error'));
    }
  });
}

export default ReportThunks;
