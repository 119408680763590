import { API } from 'aws-amplify';
import { APIResults } from 'infra/models/results.model';

class UsersAPI {
  static getMeResults = async (token: string): Promise<APIResults> => {
    const { data } = await API.get(process.env.REACT_APP_API_NAME || '', `/users/me/results`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      response: true,
    });
    return data;
  };
}

export default UsersAPI;
