import React from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import logoHumansMatter from 'ui/assets/images/humans-matter.png';
import styles from './Header.module.css';

type Props = HTMLDivAttributesWithRef;

const Header: React.FC<Props> = ({ className, ...props }): JSX.Element | null => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...props} className={`${styles.root} ${className}`}>
    <img src={logoHumansMatter} alt="" className={styles.logo} />
  </div>
);

export default Header;
