import React from 'react';

const SlideContext = React.createContext({
  isTransitioning: false as boolean,
  transitionStart: () => {},
  transitionEnd: () => {},
});

const SliderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isTransitioning, setIsTransitioning] = React.useState<boolean>(false);

  const transitionStart = () => {
    setIsTransitioning(true);
  };

  const transitionEnd = () => {
    setIsTransitioning(false);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isTransitioning,
    transitionStart,
    transitionEnd,
  };

  return <SlideContext.Provider value={value}>{children}</SlideContext.Provider>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSliderContext = () => React.useContext(SlideContext);

export default SliderProvider;
