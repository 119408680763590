import React, { useEffect, useRef, useState } from 'react';
import useContainerQueries from 'ui/hooks/use-container-queries';
import useQuery from 'ui/hooks/use-query';
import LayoutDefault, { LayoutDefaultProps } from '../LayoutDefault';
import LayoutHelloElton, { LayoutHelloHeltonProps } from '../LayoutHelloElton';

type Props = {
  children: React.ReactNode;
};

type Layout =
  | React.ForwardRefExoticComponent<LayoutDefaultProps>
  | React.ForwardRefExoticComponent<LayoutHelloHeltonProps>;

const LayoutMain: React.FC<Props> = ({ children }): JSX.Element | null => {
  const [Component, setComponent] = useState<Layout>(LayoutDefault);

  const ref = useRef<HTMLDivElement>(null);
  const cq = useContainerQueries(ref);
  const theme = useQuery('theme');

  useEffect(() => {
    document.documentElement.classList.add(`theme-${theme || 'default'}`);
    if (theme === 'hello-elton') setComponent(LayoutHelloElton);
  }, [theme]);

  return (
    <Component ref={ref} data-cq={cq.join(' ')}>
      {children}
    </Component>
  );
};

export default LayoutMain;
