export class PostMessageHelper {
  private static post = (message: string): void => {
    if (
      'ReactNativeWebView' in window &&
      window.ReactNativeWebView &&
      typeof window.ReactNativeWebView === 'object' &&
      'postMessage' in window.ReactNativeWebView &&
      typeof window.ReactNativeWebView.postMessage === 'function'
    )
      window.ReactNativeWebView.postMessage(message);
    else window.postMessage(message, '*');
  };

  static closeIframe = (): void => {
    const message = {
      type: 'ActivityExited',
      payload: null,
    };
    PostMessageHelper.post(JSON.stringify(message));
  };
}
