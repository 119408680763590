import { APISession } from 'infra/models/results.model';

export class CheckUtil {
  static hasErrorResponse = (error: unknown): error is { response: unknown } =>
    !!error && typeof error === 'object' && 'response' in error;

  static hasErrorMessage = (error: unknown): error is { message: string } =>
    !!error && typeof error === 'object' && 'message' in error && typeof error.message === 'string';

  static hasErrorDataMessage = (error: unknown): error is { data: { message: string } } => {
    const hasData = !!error && typeof error === 'object' && 'data' in error;
    return hasData && typeof error.data === 'object' && !!error.data && 'message' in error.data;
  };

  static hasErrorStatus = (error: unknown): error is { status: number } =>
    !!error && typeof error === 'object' && 'status' in error && typeof error.status === 'number';

  static hasSessions = (item: unknown): item is { sessions: Array<APISession> } =>
    !!item &&
    typeof item === 'object' &&
    'sessions' in item &&
    Array.isArray(item.sessions) &&
    item.sessions.every((session) => !!session && typeof session === 'object' && 'date' in session);

  static isIframe = (): boolean => window.self !== window.top;
}
