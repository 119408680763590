import { MutableRefObject, useEffect, useState } from 'react';

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';

const sizes: { [key in Size]: number } = {
  xs: 320, // 20rem
  sm: 384, // 24rem
  md: 448, // 28rem
  lg: 512, // 32rem
  xl: 576, // 36rem
  '2xl': 672, // 42rem
  '3xl': 768, // 48rem
  '4xl': 896, // 56rem
  '5xl': 1024, // 64rem
  '6xl': 1152, // 72rem
  '7xl': 1280, // 80rem
};

const useContainerQueries = (ref: MutableRefObject<HTMLElement | null>): Size[] => {
  const [containerSizes, setContainerSizes] = useState<Size[]>([]);

  useEffect(() => {
    const onResize = () => {
      const element = ref.current;

      if (!element) return;
      const width = element.clientWidth;

      setContainerSizes((Object.keys(sizes) as Size[]).filter((key) => width >= sizes[key as Size]));
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [ref]);

  return containerSizes;
};

export default useContainerQueries;
