import React, { SVGProps } from 'react';

export const Play = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M8 5v14l11-7z" />
  </svg>
);

export default Play;
