import dayjs from 'dayjs';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './locales/fr.json';
import 'dayjs/locale/fr';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'fr',
    resources: { fr: { translation: fr } },
    interpolation: { escapeValue: false },
  });

dayjs.locale('fr');

i18n.on('languageChanged', (lng: 'fr') => {
  document.documentElement.setAttribute('lang', lng);
  dayjs.locale(lng);
});

export default i18n;
