import React from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import Helper from 'ui/helpers/component.helper';
import Cursor, { CursorProps } from './Cursor';
import styles from './ResultsBadges.module.css';
import Sessions, { SessionsProps } from './Sessions';
import Title, { TitleProps } from './Title';

type ResultsBadgesProps = HTMLDivAttributesWithRef;

type ResultsBadgesComponent = React.ForwardRefExoticComponent<ResultsBadgesProps> & {
  Sessions: React.ForwardRefExoticComponent<SessionsProps>;
  Cursor: React.ForwardRefExoticComponent<CursorProps>;
  Title: React.ForwardRefExoticComponent<TitleProps>;
};

const ResultsBadges: ResultsBadgesComponent = React.forwardRef<HTMLDivElement, ResultsBadgesProps>(
  ({ className, children, ...props }, ref): JSX.Element | null => {
    const sessions = Helper.extractElement<SessionsProps>(children, Sessions);
    const cursor = Helper.extractElement<CursorProps>(children, Cursor);
    const title = Helper.extractElement<TitleProps>(children, Title);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`}>
        <div className={styles.top}>
          {Helper.cloneElement<CursorProps>(cursor, { className: `${styles.cursor} ${cursor?.props.className}` })}
          {Helper.cloneElement<TitleProps>(title, { className: `${styles.title} ${title?.props.className}` })}
        </div>
        {Helper.cloneElement<SessionsProps>(sessions, { className: `${styles.sessions} ${sessions?.props.className}` })}
      </div>
    );
  }
) as ResultsBadgesComponent;

ResultsBadges.Sessions = Sessions;

ResultsBadges.Cursor = Cursor;

ResultsBadges.Title = Title;

export default ResultsBadges;
