import React from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import styles from './Title.module.css';

export type TitleProps = HTMLDivAttributesWithRef;

const Title = React.forwardRef<HTMLDivElement, TitleProps>(
  ({ className, children, ...props }, ref): JSX.Element | null => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`}>
      {children}
    </div>
  )
);

export default Title;
