import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import styles from './DateString.module.css';

type Props = {
  date: Date | null;
};

type DateStringProps = HTMLDivAttributesWithRef<Props>;

const DateString = React.forwardRef<HTMLDivElement, DateStringProps>(
  ({ date, className, ...props }, ref): JSX.Element | null => {
    const { t } = useTranslation();

    if (date)
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...props} ref={ref} className={`${styles.root} ${className}`}>
          {dayjs(date).format('MMMM')}
          <br />
          {dayjs(date).year()}
        </div>
      );

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`}>
        {t('commons.coming')}
      </div>
    );
  }
);

export default DateString;
