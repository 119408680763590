import React, { ForwardedRef, PropsWithoutRef, RefAttributes } from 'react';
import { Link } from 'react-router-dom';
import { HTMLAttributesWithRef, HTMLLinkAttributesWithRef } from 'domain/models/shared.model';
import styles from './ButtonIcon.module.css';

type Props<T> = {
  as?: T;
};

type ButtonIconProps<T extends React.ElementType> = T extends 'a'
  ? HTMLLinkAttributesWithRef<Props<T>>
  : HTMLAttributesWithRef<T, Props<T>>;

type ButtonIconFCComponent = <T extends 'button' | 'a'>(
  props: PropsWithoutRef<ButtonIconProps<T>>
) => React.ReactElement | null;

const ButtonIcon: ButtonIconFCComponent = React.forwardRef(
  <T extends React.ElementType>(
    { as, className, children, ...props }: PropsWithoutRef<ButtonIconProps<T>>,
    ref?: ForwardedRef<RefAttributes<T>>
  ): JSX.Element | null => {
    const Component = as === 'a' ? Link : as || 'button';
    return (
      <Component {...props} ref={ref} className={`${styles.root} ${className}`}>
        {children}
      </Component>
    );
  }
);

export default ButtonIcon;
