import React from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import Header from './Header';
import styles from './LayoutHelloElton.module.css';

export type LayoutHelloHeltonProps = HTMLDivAttributesWithRef;

// eslint-disable-next-line react/prop-types
const LayoutHelloElton = React.forwardRef<HTMLDivElement, LayoutHelloHeltonProps>(
  ({ className, children, ...props }, ref): JSX.Element | null => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`}>
      <Header />
      <div className={styles.container}>{children}</div>
    </div>
  )
);

export default LayoutHelloElton;
