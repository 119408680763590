import React from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import Marks from 'ui/assets/images/marks.png';
import styles from './Footer.module.css';

type Props = HTMLDivAttributesWithRef;

const Footer = React.forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref): JSX.Element | null => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...props} ref={ref} className={`${styles.root} ${className}`}>
    <img src={Marks} alt="" className={styles.marks} />
  </div>
));

export default Footer;
