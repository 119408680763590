import React, { cloneElement, isValidElement, Children, RefAttributes } from 'react';
import type { Attributes, ComponentProps, FC, ReactElement, ReactNode, ForwardRefExoticComponent } from 'react';

class ComponentHelper {
  static extractElement = <T>(
    children: ReactNode,
    components: FC<T> | ForwardRefExoticComponent<T>
  ): (ReactElement<ComponentProps<FC<T>>, FC<T>> & { ref?: RefAttributes<T> }) | undefined => {
    const element = Children.toArray(children).find((child) => {
      if (!React.isValidElement(child)) return false;
      return Array.isArray(components) ? components.some((comp) => child.type === comp) : child.type === components;
    });
    return element as ReactElement<ComponentProps<FC<T>>, FC<T>> | undefined;
  };

  static extractElements = <T>(
    children: ReactNode,
    components: FC<T> | ForwardRefExoticComponent<T>
  ): (ReactElement<ComponentProps<FC<T>>, FC<T>> & { ref?: RefAttributes<T> })[] | undefined => {
    const elements = Children.toArray(children).filter((child) => {
      if (!React.isValidElement(child)) return false;
      return Array.isArray(components) ? components.some((comp) => child.type === comp) : child.type === components;
    });
    return elements as ReactElement<ComponentProps<FC<T>>, FC<T>>[] | undefined;
  };

  static cloneElement = <T>(
    element: ReactElement<ComponentProps<FC<T>>, FC<T>> | undefined,
    props: Partial<T> & Attributes = {}
  ): ReactElement<T> | undefined => {
    if (!isValidElement(element)) return undefined;
    return cloneElement(element, props);
  };
}

export default ComponentHelper;
