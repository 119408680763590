import React, { CSSProperties, useState } from 'react';
import { WeightsSession } from 'domain/models/weights.model';
import ButtonOption from 'ui/components/commons/ButtonOption';
import DateString from '../DateString';
import { Lock } from '../Icons';
import styles from './ResultsWeights.module.css';

type Props = {
  sessions: WeightsSession[];
};

const ResultsWeights: React.FC<Props> = ({ sessions }): JSX.Element | null => {
  const [current, setCurrent] = useState<number>(0);

  const currentSession: WeightsSession = sessions[current];

  if (!sessions.length) return null;

  return (
    <div className={styles.root}>
      <div className={styles.dates}>
        {sessions.map((session, i) => (
          <ButtonOption
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            isDisabled={!session.date || !session.symptoms.every(({ weight }) => !!weight)}
            isSelected={current === i}
            onClick={() => setCurrent(i)}
          >
            {session.symptoms.every(({ weight }) => !!weight) ? (
              <DateString date={session.date} />
            ) : (
              <Lock className={styles.icon} />
            )}
          </ButtonOption>
        ))}
      </div>
      <div className={styles.symptoms}>
        {currentSession.symptoms.map(({ title, weight, score, color }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className={styles.symptom}>
            <div className={styles.title}>{title}</div>
            <div className={styles.bar}>
              <div
                data-color={color}
                className={styles.progress}
                style={{ '--width': `${weight}%`, '--opacity': (weight || 0) / 100 } as CSSProperties}
              >
                {score !== undefined && <div className={styles.value}>{score.toString()}</div>}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsWeights;
