import React from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import Helper from 'ui/helpers/component.helper';
import Body, { BodyProps } from './Body';
import styles from './Section.module.css';
import Title, { TitleProps } from './Title';

type SectionProps = HTMLDivAttributesWithRef;

type SectionComponent = React.ForwardRefExoticComponent<SectionProps> & {
  Body: React.ForwardRefExoticComponent<BodyProps>;
  Title: React.ForwardRefExoticComponent<TitleProps>;
};

const Section: SectionComponent = React.forwardRef<HTMLDivElement, SectionProps>(
  ({ className, children, ...props }, ref): JSX.Element | null => {
    const body = Helper.extractElement<BodyProps>(children, Body);
    const title = Helper.extractElement<TitleProps>(children, Title);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`}>
        {Helper.cloneElement<TitleProps>(title, { className: `${styles.title} ${title?.props.className}` })}
        {Helper.cloneElement<BodyProps>(body, { className: `${styles.body} ${body?.props.className}` })}
      </div>
    );
  }
) as SectionComponent;

Section.Body = Body;

Section.Title = Title;

export default Section;
